var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.can('Import contract') && _vm.interfaceChosen=='Listing')?_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.sidebar-contrat",modifiers:{"sidebar-contrat":true}},{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"size":"md","variant":"primary"},on:{"click":function($event){_vm.isActive=true;_vm.getAuthCourtier();_vm.$emit('is-update-function', false)}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Enregistrer un contrat")])],1):_vm._e(),(_vm.can('Import contract') || _vm.can('Update contract'))?_c('b-sidebar',{ref:"sideBarContrat",attrs:{"id":"sidebar-contrat","backdrop":"","bg-variant":"white","no-header":"","right":"","shadow":"","sidebar-class":"sidebar-lg"}},[[_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0 text-uppercase text-primary font-weight-bolder"},[(_vm.isUpdate)?_c('span',[_vm._v(" Modifier le contrat "+_vm._s(_vm.composeData.n_contrat)+" ")]):_c('span',[_vm._v("Enregistrer un contrat")])]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":_vm.hideSideBare}})],1),_c('validation-observer',{ref:"contratComposeRules"},[_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('b-overlay',{attrs:{"show":_vm.showLoading,"no-wrap":""}}),_c('h4',[_vm._v("INFORMATIONS")]),(!_vm.isChild && _vm.isPortfeuille)?_c('validation-provider',{attrs:{"name":"client","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null,"label":"Client*","label-for":"client"}},[_c('v-select',{staticClass:"flex-grow-1",attrs:{"close-on-select":true,"getOptionKey":function (client) { return client.id + client.type; },"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.clientsOptions,"input-id":"client","label":"nom","placeholder":"Client"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var avatar = ref.avatar;
var nom = ref.nom;
var categorie = ref.categorie;
return [_c('b-avatar',{attrs:{"src":avatar,"size":"sm"}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(nom))]),_c('b-badge',{staticClass:"mr-2 float-right",attrs:{"variant":_vm.categorieVariant(categorie)}},[_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(categorie))+" ")])]}},{key:"selected-option",fn:function(ref){
var avatar = ref.avatar;
var nom = ref.nom;
return [_c('b-avatar',{staticClass:"border border-white",attrs:{"src":avatar,"size":"sm"}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(nom))])]}}],null,true),model:{value:(_vm.composeData.client),callback:function ($$v) {_vm.$set(_vm.composeData, "client", $$v)},expression:"composeData.client"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Aucune client disponible.")])]),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,2096387220)}):_vm._e(),(_vm.composeData.client && _vm.composeData.client.statutFiche === 'Prospect')?_c('b-form-group',{attrs:{"label":"Souhaitez vous changer le statut du prospect en client ?"}},[_c('b-form-radio-group',{staticClass:"demo-inline-spacing",attrs:{"options":_vm.optionsChangedToProspect,"name":"radio-inline"},model:{value:(_vm.composeData.isChangedToProspect),callback:function ($$v) {_vm.$set(_vm.composeData, "isChangedToProspect", $$v)},expression:"composeData.isChangedToProspect"}})],1):_vm._e(),_c('h4',[_vm._v("CONTRAT")]),_c('b-form-group',{attrs:{"label":"N° contrat*","label-for":"num_contrat"}},[_c('validation-provider',{attrs:{"name":"n° contrat","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"num_contrat","state":errors.length > 0 ? false : null,"placeholder":"N° contrat"},model:{value:(_vm.composeData.n_contrat),callback:function ($$v) {_vm.$set(_vm.composeData, "n_contrat", $$v)},expression:"composeData.n_contrat"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0])+" ")])]}}],null,false,1437684702)})],1),_c('b-form-group',{attrs:{"label":"Risque*","label-for":"Risque"}},[_c('validation-provider',{attrs:{"name":"risque","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.risquesOptions,"state":errors.length > 0 ? false : null},model:{value:(_vm.composeData.risque_id),callback:function ($$v) {_vm.$set(_vm.composeData, "risque_id", $$v)},expression:"composeData.risque_id"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0])+" ")])]}}],null,false,3521923278)})],1),_c('validation-provider',{attrs:{"name":"assureur","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Assureur *","state":errors.length > 0 ? false : null,"label-for":"assureur"}},[_c('v-select',{attrs:{"close-on-select":true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"getOptionKey":function (assureur) { return assureur.value + assureur.text; },"options":_vm.assureursOptions,"reduce":function (assureur) { return assureur.value; },"label":"text","placeholder":"assureur"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var text = ref.text;
return [_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(text))])]}}],null,true),model:{value:(_vm.composeData.assureur_id),callback:function ($$v) {_vm.$set(_vm.composeData, "assureur_id", $$v)},expression:"composeData.assureur_id"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Aucun assureur disponible.")])]),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1485507542)}),_c('b-form-group',{attrs:{"label":"Date d'effet*","label-for":"date-effet-contrat"}},[_c('validation-provider',{attrs:{"name":"date d'effet","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",class:errors.length > 0 ? 'is-invalid' : '',attrs:{"id":"date-effet-contrat","config":_vm.configDate,"placeholder":"Date d'effet"},model:{value:(_vm.composeData.date_effet),callback:function ($$v) {_vm.$set(_vm.composeData, "date_effet", $$v)},expression:"composeData.date_effet"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0])+" ")])]}}],null,false,1564220176)})],1),_c('b-form-group',{attrs:{"label":"Echéance anniversaire","label-for":"echeance-anniversaire"}},[_c('validation-provider',{attrs:{"name":"échéance d'anniversaire"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"date","raw":false,"options":_vm.options.date,"placeholder":"jour/mois"},model:{value:(_vm.composeData.echeance_anniversaire),callback:function ($$v) {_vm.$set(_vm.composeData, "echeance_anniversaire", $$v)},expression:"composeData.echeance_anniversaire"}}),(_vm.valdiation!=null)?_c('div',{staticClass:"echent"},[_vm._v("Le champ date d'échéance invalide ( jj/mm )")]):_vm._e(),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0])+" ")])]}}],null,false,2848987709)})],1),_c('b-form-group',{attrs:{"label":"Contrat concurrence","label-for":"contrat-concurrence"}},[_c('validation-provider',{attrs:{"name":"Contrat concurrence"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"demo-inline-spacing",attrs:{"id":"contrat-concurrence"}},[_c('b-form-radio',{attrs:{"name":"concurrence-radios","value":"1"},model:{value:(_vm.composeData.concurrence),callback:function ($$v) {_vm.$set(_vm.composeData, "concurrence", $$v)},expression:"composeData.concurrence"}},[_vm._v(" oui ")]),_c('b-form-radio',{attrs:{"name":"concurrence-radios","value":"0"},model:{value:(_vm.composeData.concurrence),callback:function ($$v) {_vm.$set(_vm.composeData, "concurrence", $$v)},expression:"composeData.concurrence"}},[_vm._v(" non ")])],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0])+" ")])]}}],null,false,3590635478)})],1),_c('b-form-group',{attrs:{"label":"Type de paiement","label-for":"typeFractionnement"}},[_c('b-form-select',{attrs:{"options":_vm.typeFractionnementOptions},model:{value:(_vm.composeData.typeFractionnement),callback:function ($$v) {_vm.$set(_vm.composeData, "typeFractionnement", $$v)},expression:"composeData.typeFractionnement"}})],1),_c('b-form-group',{attrs:{"label":"Statut*","label-for":"statut"}},[_c('validation-provider',{attrs:{"name":"statut","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.statutOptions,"state":errors.length > 0 ? false : null},model:{value:(_vm.composeData.statut),callback:function ($$v) {_vm.$set(_vm.composeData, "statut", $$v)},expression:"composeData.statut"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0])+" ")])]}}],null,false,4269297135)})],1),(_vm.isRésilié || _vm.isTemporaire)?_c('b-form-group',{attrs:{"label":"Date de fin","label-for":"date-fin-contrat"}},[_c('validation-provider',{attrs:{"name":"date de fin"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",class:errors.length > 0 ? 'is-invalid' : '',attrs:{"id":"date-fin-contrat","config":_vm.configDate,"placeholder":"Date de fin"},model:{value:(_vm.composeData.date_fin),callback:function ($$v) {_vm.$set(_vm.composeData, "date_fin", $$v)},expression:"composeData.date_fin"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0])+" ")])]}}],null,false,3069017287)})],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Modalité de paiement","label-for":"Modalite_paiement"}},[_c('b-form-select',{attrs:{"options":_vm.modalitePaiementOptions},model:{value:(_vm.composeData.modalite_paiement),callback:function ($$v) {_vm.$set(_vm.composeData, "modalite_paiement", $$v)},expression:"composeData.modalite_paiement"}})],1),_c('b-form-group',{attrs:{"label":"Prime TTC","label-for":"budget"}},[_c('validation-provider',{attrs:{"name":"Prime TTC"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",class:errors.length > 0 ? 'is-invalid' : '',attrs:{"id":"budget","options":_vm.options.number,"raw":false,"state":errors.length > 0 ? false : null,"placeholder":"10 000,00"},model:{value:(_vm.composeData.budget),callback:function ($$v) {_vm.$set(_vm.composeData, "budget", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"composeData.budget"}}),(_vm.validbudget!=null)?_c('div',{staticClass:"echent"},[_vm._v("Le champ Prime ttc est invalide ")]):_vm._e(),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0])+" ")])]}}],null,false,3919598918)})],1),_c('b-form-group',{staticStyle:{"margin-top":"10px"},attrs:{"label":"Type d’origine","label-for":"Type-d’origine"}},[_c('b-form-select',{attrs:{"options":_vm.origine,"id":"origine"},model:{value:(_vm.composeData.origine),callback:function ($$v) {_vm.$set(_vm.composeData, "origine", $$v)},expression:"composeData.origine"}})],1),(_vm.composeData.origine === 'Autre')?_c('b-form-group',{attrs:{"label":"Précisez","label-for":"origineautre"}},[_c('b-form-input',{attrs:{"id":"origineautre","placeholder":"Précisez l’origine"},model:{value:(_vm.composeData.origineautre),callback:function ($$v) {_vm.$set(_vm.composeData, "origineautre", $$v)},expression:"composeData.origineautre"}})],1):_vm._e(),_c('validation-provider',{attrs:{"name":"gestionnaire"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null,"label":"Gestionnaire","label-for":"gestionnaire"}},[_c('v-select',{staticClass:"flex-grow-1",attrs:{"close-on-select":true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.commercialOptions,"input-id":"gestionnaire","label":"nom","placeholder":"Gestionnaire"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var avatar = ref.avatar;
var nom = ref.nom;
return [_c('b-avatar',{attrs:{"src":avatar,"size":"sm"}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(nom))])]}},{key:"selected-option",fn:function(ref){
var avatar = ref.avatar;
var nom = ref.nom;
return [_c('b-avatar',{staticClass:"border border-white",attrs:{"src":avatar,"size":"sm"}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(nom))])]}}],null,true),model:{value:(_vm.composeData.gestionnaire),callback:function ($$v) {_vm.$set(_vm.composeData, "gestionnaire", $$v)},expression:"composeData.gestionnaire"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Aucune createur disponible.")])]),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0])+" ")])],1)]}}],null,false,3664462645)}),_c('validation-provider',{attrs:{"name":"createur"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null,"label":"Crée par","label-for":"Crée-par"}},[_c('v-select',{staticClass:"flex-grow-1",attrs:{"close-on-select":true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.commercialOptions,"input-id":"createur","label":"nom","placeholder":"Crée par","disabled":_vm.isUpdate},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var avatar = ref.avatar;
var nom = ref.nom;
return [_c('b-avatar',{attrs:{"src":avatar,"size":"sm"}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(nom))])]}},{key:"selected-option",fn:function(ref){
var avatar = ref.avatar;
var nom = ref.nom;
return [_c('b-avatar',{staticClass:"border border-white",attrs:{"src":avatar,"size":"sm"}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(nom))])]}}],null,true),model:{value:(_vm.composeData.createur),callback:function ($$v) {_vm.$set(_vm.composeData, "createur", $$v)},expression:"composeData.createur"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Aucune createur disponible.")])]),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0])+" ")])],1)]}}],null,false,2131607895)}),_c('validation-provider',{attrs:{"name":"intermediaire"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null,"label":"Intermédiaire (s)","label-for":"intermediaire"}},[_c('v-select',{staticClass:"flex-grow-1",attrs:{"close-on-select":true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.commercialOptions,"input-id":"intermediaire","label":"nom","placeholder":"Intermédiaire (s)","multiple":""},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var avatar = ref.avatar;
var nom = ref.nom;
return [_c('b-avatar',{attrs:{"src":avatar,"size":"sm"}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(nom))])]}},{key:"selected-option",fn:function(ref){
var avatar = ref.avatar;
var nom = ref.nom;
return [_c('b-avatar',{staticClass:"border border-white",attrs:{"src":avatar,"size":"sm"}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(nom))])]}}],null,true),model:{value:(_vm.composeData.intermediaire),callback:function ($$v) {_vm.$set(_vm.composeData, "intermediaire", $$v)},expression:"composeData.intermediaire"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Aucune intermediaire disponible.")])]),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0])+" ")])],1)]}}],null,false,2655082188)}),_c('div',{staticClass:"my-2 d-flex align-items-center"},[_c('h4',{staticClass:"mr-2 mb-0"},[_vm._v("COMMISSIONS")]),_c('b-form-group',{staticClass:"mb-0"},[_c('label',{staticClass:"float-left pl-2"},[_vm._v("Prévisionnel")]),_c('b-form-checkbox',{attrs:{"id":"previsionnel","value":1,"unchecked-value":null},model:{value:(_vm.composeData.previsionnel),callback:function ($$v) {_vm.$set(_vm.composeData, "previsionnel", $$v)},expression:"composeData.previsionnel"}})],1)],1),_c('validation-provider',{attrs:{"name":"taux de commission"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Taux de commission","label-for":"taux_commission"}},[_c('b-input-group',{class:errors.length > 0 ? 'is-invalid' : '',attrs:{"append":"%"}},[_c('cleave',{staticClass:"form-control",attrs:{"state":errors.length > 0 ? false : null,"placeholder":"10,00","options":_vm.options.percent,"raw":false},model:{value:(_vm.composeData.taux_commission),callback:function ($$v) {_vm.$set(_vm.composeData, "taux_commission", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"composeData.taux_commission"}})],1),(_vm.validtaux_commission!=null)?_c('div',{staticClass:"echent"},[_vm._v("Le champ taux de commission est invalide ")]):_vm._e(),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0])+" ")])],1)]}}],null,false,2597164241)}),_c('b-form-group',{attrs:{"label":"Frais de courtage","label-for":"frais de courtage"}},[_c('validation-provider',{attrs:{"name":"frais de courtage"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",class:errors.length > 0 ? 'is-invalid' : '',attrs:{"id":"frais de courtage","options":_vm.options.number,"raw":false,"state":errors.length > 0 ? false : null,"placeholder":"10,00"},model:{value:(_vm.composeData.frais_courtage),callback:function ($$v) {_vm.$set(_vm.composeData, "frais_courtage", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"composeData.frais_courtage"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0])+" ")])]}}],null,false,1490933044)})],1),_c('b-form-group',{attrs:{"label":"Commission récurrente","label-for":"commission recurrente"}},[_c('validation-provider',{attrs:{"name":"commission recurrente"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",class:errors.length > 0 ? 'is-invalid' : '',attrs:{"id":"commission recurrente","options":_vm.options.number,"raw":false,"state":errors.length > 0 ? false : null,"placeholder":"10 000,00"},model:{value:(_vm.composeData.commission_recurrente),callback:function ($$v) {_vm.$set(_vm.composeData, "commission_recurrente", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"composeData.commission_recurrente"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0])+" ")])]}}],null,false,1722587141)})],1),(_vm.composeData.typeFractionnement == 'PRIME_UNIQUE' || _vm.composeData.typeFractionnement == 'VERSEMENTS_LIBRES')?_c('b-form-group',{attrs:{"label":"Commission unique précompte","label-for":"commission unique precompte"}},[_c('validation-provider',{attrs:{"name":"commission unique precompte"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",class:errors.length > 0 ? 'is-invalid' : '',attrs:{"id":"commission unique precompte","options":_vm.options.number,"raw":false,"state":errors.length > 0 ? false : null,"placeholder":"10 000,00"},model:{value:(_vm.composeData.commission_unique_precompte),callback:function ($$v) {_vm.$set(_vm.composeData, "commission_unique_precompte", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"composeData.commission_unique_precompte"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0])+" ")])]}}],null,false,3800941914)})],1):_vm._e(),_c('h4',[_vm._v("RATTACHEMENT")]),_c('b-form-group',{attrs:{"label":"Courtier","label-for":"courtier"}},[_c('b-form-input',{attrs:{"id":"courtier","disabled":"","placeholder":"Courtier"},model:{value:(_vm.currentUser.courtier_user[0].courtier.personne_morale.denomination_commercial),callback:function ($$v) {_vm.$set(_vm.currentUser.courtier_user[0].courtier.personne_morale, "denomination_commercial", $$v)},expression:"currentUser.courtier_user[0].courtier.personne_morale.denomination_commercial"}})],1),(false)?_c('validation-provider',{attrs:{"name":"commercial","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null,"label":"Commercial*","label-for":"commercial"}},[_c('v-select',{staticClass:"flex-grow-1",attrs:{"close-on-select":true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.commercialOptions,"input-id":"commercial","label":"nom","placeholder":"Commercial"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var avatar = ref.avatar;
var nom = ref.nom;
return [_c('b-avatar',{attrs:{"src":avatar,"size":"sm"}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(nom))])]}},{key:"selected-option",fn:function(ref){
var avatar = ref.avatar;
var nom = ref.nom;
return [_c('b-avatar',{staticClass:"border border-white",attrs:{"src":avatar,"size":"sm"}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(nom))])]}}],null,true),model:{value:(_vm.composeData.commercial),callback:function ($$v) {_vm.$set(_vm.composeData, "commercial", $$v)},expression:"composeData.commercial"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Aucune commercial disponible.")])]),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0])+" ")])],1)]}}],null,false,1453391424)}):_vm._e(),_c('h4',[_vm._v("DOCUMENTS")]),_c('app-collapse',[_c('app-collapse-item',{attrs:{"title":"Documents de souscription"}},[_c('b-form-group',{staticClass:"mt-2",attrs:{"label-for":"h-document"}},[_c('validation-provider',{attrs:{"name":"document"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Conditions particulières'),expression:"'Conditions particulières'",modifiers:{"hover":true}}],staticClass:"btn-icon",attrs:{"variant":errors.length > 0 ? 'outline-danger' : 'outline-primary'},on:{"click":_vm.openConditionParticulier}},[_c('b-form-file',{staticClass:"d-none",attrs:{"type":"file","id":"openConditionParticulier"},model:{value:(_vm.composeData.fileConditionParticulier),callback:function ($$v) {_vm.$set(_vm.composeData, "fileConditionParticulier", $$v)},expression:"composeData.fileConditionParticulier"}}),_c('feather-icon',{attrs:{"icon":"UploadCloudIcon"}})],1)],1),_c('b-form-input',{attrs:{"disabled":"","placeholder":"Conditions particulières","value":_vm.composeData.fileConditionParticulier ? _vm.composeData.fileConditionParticulier.name : '',"state":errors.length > 0 ? false : null}})],1)]}}],null,false,1339458559)})],1),_c('b-form-group',{staticClass:"mt-2",attrs:{"label-for":"h-document"}},[_c('validation-provider',{attrs:{"name":"document"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Bulletin d\'adhésion signé'),expression:"'Bulletin d\\'adhésion signé'",modifiers:{"hover":true}}],staticClass:"btn-icon",attrs:{"variant":errors.length > 0 ? 'outline-danger' : 'outline-primary'},on:{"click":_vm.openBulteinAdhesionSigne}},[_c('b-form-file',{staticClass:"d-none",attrs:{"type":"file","id":"openBulteinAdhesionSigne"},model:{value:(_vm.composeData.fileBulteinAdhésionSigne),callback:function ($$v) {_vm.$set(_vm.composeData, "fileBulteinAdhésionSigne", $$v)},expression:"composeData.fileBulteinAdhésionSigne"}}),_c('feather-icon',{attrs:{"icon":"UploadCloudIcon"}})],1)],1),_c('b-form-input',{attrs:{"disabled":"","placeholder":"Bulletin d'adhésion signé","value":_vm.composeData.fileBulteinAdhésionSigne ? _vm.composeData.fileBulteinAdhésionSigne.name : '',"state":errors.length > 0 ? false : null}})],1)]}}],null,false,3455365544)})],1),_c('b-form-group',{staticClass:"mt-2",attrs:{"label-for":"h-document"}},[_c('validation-provider',{attrs:{"name":"document"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Fiche d\'information et de conseil'),expression:"'Fiche d\\'information et de conseil'",modifiers:{"hover":true}}],staticClass:"btn-icon",attrs:{"variant":errors.length > 0 ? 'outline-danger' : 'outline-primary'},on:{"click":_vm.openFicheInformationConseil}},[_c('b-form-file',{staticClass:"d-none",attrs:{"type":"file","id":"openFicheInformationConseil"},model:{value:(_vm.composeData.fileFicheInformationConseil),callback:function ($$v) {_vm.$set(_vm.composeData, "fileFicheInformationConseil", $$v)},expression:"composeData.fileFicheInformationConseil"}}),_c('feather-icon',{attrs:{"icon":"UploadCloudIcon"}})],1)],1),_c('b-form-input',{attrs:{"disabled":"","placeholder":"Fiche d'information et de conseil","value":_vm.composeData.fileFicheInformationConseil ? _vm.composeData.fileFicheInformationConseil.name : '',"state":errors.length > 0 ? false : null}})],1)]}}],null,false,2274486904)})],1)],1),_c('app-collapse-item',{attrs:{"title":"Documents produit"}},[_c('b-form-group',{staticClass:"mt-2",attrs:{"label-for":"h-document"}},[_c('validation-provider',{attrs:{"name":"document"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('IPID'),expression:"'IPID'",modifiers:{"hover":true}}],staticClass:"btn-icon",attrs:{"variant":errors.length > 0 ? 'outline-danger' : 'outline-primary'},on:{"click":_vm.openfileIpid}},[_c('b-form-file',{staticClass:"d-none",attrs:{"type":"file","id":"openfileIpid"},model:{value:(_vm.composeData.fileIpid),callback:function ($$v) {_vm.$set(_vm.composeData, "fileIpid", $$v)},expression:"composeData.fileIpid"}}),_c('feather-icon',{attrs:{"icon":"UploadCloudIcon"}})],1)],1),_c('b-form-input',{attrs:{"disabled":"","placeholder":"IPID","value":_vm.composeData.fileIpid ? _vm.composeData.fileIpid.name : '',"state":errors.length > 0 ? false : null}})],1)]}}],null,false,4256414935)})],1),_c('b-form-group',{staticClass:"mt-2",attrs:{"label-for":"h-document"}},[_c('validation-provider',{attrs:{"name":"document"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Conditions generale'),expression:"'Conditions generale'",modifiers:{"hover":true}}],staticClass:"btn-icon",attrs:{"variant":errors.length > 0 ? 'outline-danger' : 'outline-primary'},on:{"click":_vm.openFileConditionsGenerale}},[_c('b-form-file',{staticClass:"d-none",attrs:{"type":"file","id":"openFileConditionsGenerale"},model:{value:(_vm.composeData.fileConditionsGenerale),callback:function ($$v) {_vm.$set(_vm.composeData, "fileConditionsGenerale", $$v)},expression:"composeData.fileConditionsGenerale"}}),_c('feather-icon',{attrs:{"icon":"UploadCloudIcon"}})],1)],1),_c('b-form-input',{attrs:{"disabled":"","placeholder":"Condition générale","value":_vm.composeData.fileConditionsGenerale ? _vm.composeData.fileConditionsGenerale.name : '',"state":errors.length > 0 ? false : null}})],1)]}}],null,false,1112442148)})],1),_c('b-form-group',{staticClass:"mt-2",attrs:{"label-for":"h-document"}},[_c('validation-provider',{attrs:{"name":"document"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Notice d\'information'),expression:"'Notice d\\'information'",modifiers:{"hover":true}}],staticClass:"btn-icon",attrs:{"variant":errors.length > 0 ? 'outline-danger' : 'outline-primary'},on:{"click":_vm.openFileNoticeInformation}},[_c('b-form-file',{staticClass:"d-none",attrs:{"type":"file","id":"openFileNoticeInformation"},model:{value:(_vm.composeData.fileNoticeInformation),callback:function ($$v) {_vm.$set(_vm.composeData, "fileNoticeInformation", $$v)},expression:"composeData.fileNoticeInformation"}}),_c('feather-icon',{attrs:{"icon":"UploadCloudIcon"}})],1)],1),_c('b-form-input',{attrs:{"disabled":"","placeholder":"Notice D'information","value":_vm.composeData.fileNoticeInformation ? _vm.composeData.fileNoticeInformation.name : '',"state":errors.length > 0 ? false : null}})],1)]}}],null,false,1358468104)})],1),_c('b-form-group',{staticClass:"mt-2",attrs:{"label-for":"h-document"}},[_c('validation-provider',{attrs:{"name":"document"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Fiche d\'information et de conseil'),expression:"'Fiche d\\'information et de conseil'",modifiers:{"hover":true}}],staticClass:"btn-icon",attrs:{"variant":errors.length > 0 ? 'outline-danger' : 'outline-primary'},on:{"click":_vm.openFileTableauGarantie}},[_c('b-form-file',{staticClass:"d-none",attrs:{"type":"file","id":"openFileTableauGarantie"},model:{value:(_vm.composeData.fileTableauGarantie),callback:function ($$v) {_vm.$set(_vm.composeData, "fileTableauGarantie", $$v)},expression:"composeData.fileTableauGarantie"}}),_c('feather-icon',{attrs:{"icon":"UploadCloudIcon"}})],1)],1),_c('b-form-input',{attrs:{"disabled":"","placeholder":"Tableau de garantie","value":_vm.composeData.fileTableauGarantie ? _vm.composeData.fileTableauGarantie.name : '',"state":errors.length > 0 ? false : null}})],1)]}}],null,false,223461674)})],1)],1)],1),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary"},on:{"click":_vm.recordContrat}},[_vm._v(" Enregistrer ")])],1)],1)],1)]],2):_vm._e(),_c('b-sidebar',{ref:"sideBarImportContrat",attrs:{"id":"sidebar-importNewContrat","bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0 text-uppercase text-primary font-weight-bolder"},[_vm._v("Importer un document contrat")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"validateImportContratRules"},[_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[(false)?_c('b-form-group',{attrs:{"label":"Nature de document","label-for":"h-famille"}},[_c('validation-provider',{attrs:{"name":"nature de document","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"h-famille-autre-document","options":_vm.optionsFamille,"state":errors.length > 0 ? false : null},on:{"change":function($event){return _vm.changeListoptionType()}},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("-- Choisissez --")])]},proxy:true}],null,true),model:{value:(_vm.famille),callback:function ($$v) {_vm.famille=$$v},expression:"famille"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Type de document","label-for":"h-type"}},[_c('validation-provider',{attrs:{"name":"type de document","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"h-type-autre-document","options":_vm.optionsType,"state":errors.length > 0 ? false : null},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("-- Choisissez --")])]},proxy:true}],null,true),model:{value:(_vm.composeData.idType),callback:function ($$v) {_vm.$set(_vm.composeData, "idType", $$v)},expression:"composeData.idType"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.isAutre)?_c('b-form-group',{attrs:{"label":"Nom du document*","label-for":"h-nom-document"}},[_c('validation-provider',{attrs:{"name":"nom du document","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-nom-document-autre-document","placeholder":"Nom du document","state":errors.length > 0 ? false : null},model:{value:(_vm.composeData.nom),callback:function ($$v) {_vm.$set(_vm.composeData, "nom", $$v)},expression:"composeData.nom"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('b-form-group',{staticClass:"mt-2",attrs:{"label-for":"h-document"}},[_c('validation-provider',{attrs:{"name":"document","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Parcourir le fichier'),expression:"'Parcourir le fichier'",modifiers:{"hover":true}}],staticClass:"btn-icon",attrs:{"variant":errors.length > 0 ? 'outline-danger' : 'outline-primary'},on:{"click":_vm.openToSelectFile}},[_c('b-form-file',{staticClass:"d-none",attrs:{"type":"file","id":"fileautredocumentPro"},model:{value:(_vm.composeData.file),callback:function ($$v) {_vm.$set(_vm.composeData, "file", $$v)},expression:"composeData.file"}}),_c('feather-icon',{attrs:{"icon":"UploadCloudIcon"}})],1)],1),_c('b-form-input',{attrs:{"disabled":"","placeholder":"Choisissez un document","value":_vm.composeData.file ? _vm.composeData.file.name : '',"state":errors.length > 0 ? false : null}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary"},on:{"click":_vm.recordDocument}},[_vm._v(" Enregistrer ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }